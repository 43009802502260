import { defineStore } from 'pinia'
import { apiPost } from '~/services/api'

interface AuthPayload {
  phone: string
  code: string
}

interface ApiResponse {
  data: {
    value: {
      token: string
    }
  }
  errors: any
  status: number
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authenticated: false,
    loading: false,
    loginError: null,
    is_just_created: null,
    ResStatus: 0,
  }),
  actions: {
    async authenticateUser(payload: AuthPayload) {
      try {
        const { data, errors, status } = (await apiPost({
          url: 'api/auth/verify-enter-code',
          body: {
            phone: payload.phone,
            code: payload.code,
          },
        })) as unknown as ApiResponse

        const token = useCookie('token')
        if (data && data.value && data.value.token) {
          token.value = data.value.token
          this.is_just_created = data.value.is_just_created
          this.authenticated = true
        } else {
          this.loginError = errors
          this.ResStatus = status
          throw new Error('Не удалось выполнить аутентификацию')
        }
      } catch (error: any) {
        console.error(error)
      }
    },
    async logoutUser() {
      try {
        await apiPost({ url: 'api/auth/logout' })
        const token = useCookie('token')
        this.authenticated = false
        token.value = null
      } catch (e) {
        console.log(e)
      }
    },
  },
})
